"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ERROR_CODES = exports.ERROR_TYPES = void 0;
var ERROR_TYPES;
(function (ERROR_TYPES) {
    ERROR_TYPES["INVALID_REQUEST_ERROR"] = "INVALID_REQUEST_ERROR";
    ERROR_TYPES["MODERATION_NUDGE_ERROR"] = "MODERATION_NUDGE_ERROR";
})(ERROR_TYPES = exports.ERROR_TYPES || (exports.ERROR_TYPES = {}));
var ERROR_CODES;
(function (ERROR_CODES) {
    /**
     * STORY_CLOSED is used when submitting a comment on a closed story.
     */
    ERROR_CODES["STORY_CLOSED"] = "STORY_CLOSED";
    /**
     * COMMENTING_DISABLED is used when submitting a comment while commenting has
     * been disabled.
     */
    ERROR_CODES["COMMENTING_DISABLED"] = "COMMENTING_DISABLED";
    /**
     * COMMENT_BODY_TOO_SHORT is used when a submitted comment body is too short.
     */
    ERROR_CODES["COMMENT_BODY_TOO_SHORT"] = "COMMENT_BODY_TOO_SHORT";
    /**
     * COMMENT_BODY_EXCEEDS_MAX_LENGTH is used when a submitted comment body
     * exceeds the maximum length.
     */
    ERROR_CODES["COMMENT_BODY_EXCEEDS_MAX_LENGTH"] = "COMMENT_BODY_EXCEEDS_MAX_LENGTH";
    /**
     * STORY_URL_NOT_PERMITTED is used when the given Story being created or
     * updated does not have a URL that is permitted by the Tenant.
     */
    ERROR_CODES["STORY_URL_NOT_PERMITTED"] = "STORY_URL_NOT_PERMITTED";
    /**
     * TOKEN_NOT_FOUND is used when a Token is referenced by ID but can not be
     * found to be associated with the given User.
     */
    ERROR_CODES["TOKEN_NOT_FOUND"] = "TOKEN_NOT_FOUND";
    /**
     * DUPLICATE_STORY_ID is used when trying to create a Story with the same ID
     * as another Story.
     */
    ERROR_CODES["DUPLICATE_STORY_ID"] = "DUPLICATE_STORY_ID";
    /**
     * DUPLICATE_STORY_URL is used when trying to create a Story with the same URL
     * as another Story.
     */
    ERROR_CODES["DUPLICATE_STORY_URL"] = "DUPLICATE_STORY_URL";
    /**
     * DUPLICATE_DSA_REPORT is used when trying to create a DSA report for a user who has already
     * reported a comment for illegal content.
     */
    ERROR_CODES["DUPLICATE_DSA_REPORT"] = "DUPLICATE_DSA_REPORT";
    /**
     * DUPLICATE_FLAIR_BADGE is used when trying to create a flair badge for a
     * tenant that already includes that flair badge.
     */
    ERROR_CODES["DUPLICATE_FLAIR_BADGE"] = "DUPLICATE_FLAIR_BADGE";
    /**
     * EMAIL_ALREADY_SET is used when trying to set the email address on a User
     * when the User already has an email address associated with their account.
     */
    ERROR_CODES["EMAIL_ALREADY_SET"] = "EMAIL_ALREADY_SET";
    /**
     * EMAIL_DOMAIN_PROTECTED is used when trying to ban an email domain that is
     * so common that it is most likely being done in error.
     */
    ERROR_CODES["EMAIL_DOMAIN_PROTECTED"] = "EMAIL_DOMAIN_PROTECTED";
    /**
     * EMAIL_NOT_SET is used when performing an operation that requires that the
     * email address be set on the User, and it is not.
     */
    ERROR_CODES["EMAIL_NOT_SET"] = "EMAIL_NOT_SET";
    /**
     * TENANT_NOT_FOUND is used when the domain being queried does not correspond
     * to a Tenant.
     */
    ERROR_CODES["TENANT_NOT_FOUND"] = "TENANT_NOT_FOUND";
    /**
     * INTERNAL_ERROR is returned when a situation occurs that is not user facing,
     * such as an unexpected index violation, or a database connection error.
     */
    ERROR_CODES["INTERNAL_ERROR"] = "INTERNAL_ERROR";
    /**
     * DUPLICATE_USER is returned when a user was attempted to be created twice.
     * This can occur when a User creates an account with one method, then
     * attempts to create another user account with another method yielding the
     * same email address.
     */
    ERROR_CODES["DUPLICATE_USER"] = "DUPLICATE_USER";
    /**
     * TOKEN_INVALID is returned when the provided token has an invalid format.
     */
    ERROR_CODES["TOKEN_INVALID"] = "TOKEN_INVALID";
    /**
     * DUPLICATE_EMAIL is returned when a user attempts to create an account
     * with the same email address as another user.
     */
    ERROR_CODES["DUPLICATE_EMAIL"] = "DUPLICATE_EMAIL";
    /**
     * DUPLICATE_EMAIL_DOMAIN is returned when a user attempts to add configuration
     * for an email domain already configured on the tenant.
     */
    ERROR_CODES["DUPLICATE_EMAIL_DOMAIN"] = "DUPLICATE_EMAIL_DOMAIN";
    /**
     * LOCAL_PROFILE_ALREADY_SET is returned when the user attempts to associate a
     * local profile when the user already has one.
     */
    ERROR_CODES["LOCAL_PROFILE_ALREADY_SET"] = "LOCAL_PROFILE_ALREADY_SET";
    /**
     * LOCAL_PROFILE_NOT_SET is returned when the user attempts to perform an
     * action which requires a local profile to be associated with the user.
     */
    ERROR_CODES["LOCAL_PROFILE_NOT_SET"] = "LOCAL_PROFILE_NOT_SET";
    /**
     * SSO_PROFILE_NOT_SET is returned when the user attempts to perform an
     * action which requires an SSO profile to be associated with the user.
     */
    ERROR_CODES["SSO_PROFILE_NOT_SET"] = "SSO_PROFILE_NOT_SET";
    /**
     * USERNAME_ALREADY_SET is returned when the user attempts to set a username
     * via the set operations when they already have a username associated with
     * their account.
     */
    ERROR_CODES["USERNAME_ALREADY_SET"] = "USERNAME_ALREADY_SET";
    /**
     * USERNAME_CONTAINS_INVALID_CHARACTERS is returned when the user attempts to
     * associate a new username that contains invalid characters.
     */
    ERROR_CODES["USERNAME_CONTAINS_INVALID_CHARACTERS"] = "USERNAME_CONTAINS_INVALID_CHARACTERS";
    /**
     * USERNAME_EXCEEDS_MAX_LENGTH is returned when the user attempts to associate
     * a new username that exceeds the maximum length.
     */
    ERROR_CODES["USERNAME_EXCEEDS_MAX_LENGTH"] = "USERNAME_EXCEEDS_MAX_LENGTH";
    /**
     * USERNAME_UPDATED_WITHIN_WINDOW is returned when the user attempts to associate
     * a new username when they have previously changed their username within ALLOWED_USERNAME_CHANGE_FREQUENCY
     */
    ERROR_CODES["USERNAME_UPDATED_WITHIN_WINDOW"] = "USERNAME_UPDATED_WITHIN_WINDOW";
    /**
     * USERNAME_TOO_SHORT is returned when the user attempts to associate a new
     * username that is too short.
     */
    ERROR_CODES["USERNAME_TOO_SHORT"] = "USERNAME_TOO_SHORT";
    /**
     * PASSWORD_TOO_SHORT is returned when the user attempts to associate a new
     * password but it is too short.
     */
    ERROR_CODES["PASSWORD_TOO_SHORT"] = "PASSWORD_TOO_SHORT";
    /**
     * PASSWORD_INCORRECT is returned when a logged in operation that requires the
     * password returns the wrong password.
     */
    ERROR_CODES["PASSWORD_INCORRECT"] = "PASSWORD_INCORRECT";
    /**
     * EMAIL_INVALID_FORMAT is returned when when the user attempts to associate a
     * new email address that is not a valid email address.
     */
    ERROR_CODES["EMAIL_INVALID_FORMAT"] = "EMAIL_INVALID_FORMAT";
    /**
     * EMAIL_EXCEEDS_MAX_LENGTH is returned when when the user attempts to
     * associate a new email address and it exceeds the maximum length.
     */
    ERROR_CODES["EMAIL_EXCEEDS_MAX_LENGTH"] = "EMAIL_EXCEEDS_MAX_LENGTH";
    /**
     * USER_NOT_FOUND is returned when the user being looked up via an ID does not
     * exist in the database.
     */
    ERROR_CODES["USER_NOT_FOUND"] = "USER_NOT_FOUND";
    /**
     * NOT_FOUND is returned when attempting to access a resource that does not
     * exist.
     */
    ERROR_CODES["NOT_FOUND"] = "NOT_FOUND";
    /**
     * TENANT_INSTALLED_ALREADY is returned when attempting to install a Tenant
     * when the Tenant is already setup when in single-tenant mode.
     */
    ERROR_CODES["TENANT_INSTALLED_ALREADY"] = "TENANT_INSTALLED_ALREADY";
    /**
     * USER_NOT_ENTITLED is returned when a user attempts to perform an action
     * that they are not entitled to.
     */
    ERROR_CODES["USER_NOT_ENTITLED"] = "USER_NOT_ENTITLED";
    /**
     * STORY_NOT_FOUND is returned when a Story can not be found with the given
     * ID.
     */
    ERROR_CODES["STORY_NOT_FOUND"] = "STORY_NOT_FOUND";
    /**
     * COMMENT_NOT_FOUND is returned when a Comment can not be found with the
     * given ID.
     */
    ERROR_CODES["COMMENT_NOT_FOUND"] = "COMMENT_NOT_FOUND";
    /**
     * COMMENT_REVISION_NOT_FOUND is returned when a Comment was found but the
     * targeted revision was not found.
     */
    ERROR_CODES["COMMENT_REVISION_NOT_FOUND"] = "COMMENT_REVISION_NOT_FOUND";
    /**
     * PARENT_COMMENT_REJECTED is returned when a Comment's parent
     * comment has been rejected.
     */
    ERROR_CODES["PARENT_COMMENT_REJECTED"] = "PARENT_COMMENT_REJECTED";
    /**
     * ANCESTOR_REJECTED is returned when a Comment's ancestor
     * has been rejected.
     */
    ERROR_CODES["ANCESTOR_REJECTED"] = "ANCESTOR_REJECTED";
    /**
     * AUTHENTICATION_ERROR is returned when a general authentication error has
     * occurred and the request can not be processed.
     */
    ERROR_CODES["AUTHENTICATION_ERROR"] = "AUTHENTICATION_ERROR";
    /**
     * INVALID_CREDENTIALS is returned when the passed credentials are invalid.
     */
    ERROR_CODES["INVALID_CREDENTIALS"] = "INVALID_CREDENTIALS";
    /**
     * TOXIC_COMMENT is returned when a comment is detected as Toxic and nudging
     * is enabled.
     */
    ERROR_CODES["TOXIC_COMMENT"] = "TOXIC_COMMENT";
    /**
     * SPAM_COMMENT is returned when a comment is detected as spam and nudging is
     * enabled.
     */
    ERROR_CODES["SPAM_COMMENT"] = "SPAM_COMMENT";
    /**
     * USER_ALREADY_SUSPENDED is returned when a moderator or administrator
     * attempts to suspend a user that already has an active suspension.
     */
    ERROR_CODES["USER_ALREADY_SUSPENDED"] = "USER_ALREADY_SUSPENDED";
    /**
     * USER_ALREADY_BANNED is returned when a moderator or administrator attempts
     * to ban a user that is already banned.
     */
    ERROR_CODES["USER_ALREADY_BANNED"] = "USER_ALREADY_BANNED";
    /**
     * USER_SUSPENDED is returned when the user attempts to perform an action that
     * is not permitted if they are suspended.
     */
    ERROR_CODES["USER_SUSPENDED"] = "USER_SUSPENDED";
    /**
     * USER_WARNED is returned when the user attempts to perform an action that
     * is not permitted if they are warned and have not acknowledged the warning.
     */
    ERROR_CODES["USER_WARNED"] = "USER_WARNED";
    /**
     * USER_BANNED is returned when the user attempts to perform an action that
     * is not permitted if they are banned.
     */
    ERROR_CODES["USER_BANNED"] = "USER_BANNED";
    /**
     * USER_SITE_BANNED is returned when the user attempts to perform an action
     * that is not permitted if they are banned on that site.
     */
    ERROR_CODES["USER_SITE_BANNED"] = "USER_SITE_BANNED";
    /**
     * MODERATOR_CANNOT_BE_BANNED_ON_SITE is returned when one or more of the sites
     * that a user is being banned on is included in the user's moderation scopes.
     */
    ERROR_CODES["MODERATOR_CANNOT_BE_BANNED_ON_SITE"] = "MODERATOR_CANNOT_BE_BANNED_ON_SITE";
    /**
     * USER_CANNOT_BE_IGNORED is returned when the user attempts to ignore
     * a user that is not allowed to be ignored. This is usually because the
     * user is staff member.
     */
    ERROR_CODES["USER_CANNOT_BE_IGNORED"] = "USER_CANNOT_BE_IGNORED";
    /**
     * INTEGRATION_DISABLED is returned when an operation is attempted against an
     * integration that has been disabled.
     */
    ERROR_CODES["INTEGRATION_DISABLED"] = "INTEGRATION_DISABLED";
    /**
     * PASSWORD_RESET_TOKEN_EXPIRED is returned when a given password reset token has
     * expired.
     */
    ERROR_CODES["PASSWORD_RESET_TOKEN_EXPIRED"] = "PASSWORD_RESET_TOKEN_EXPIRED";
    /**
     * EMAIL_CONFIRM_TOKEN_EXPIRED is returned when a given email confirmation
     * token has expired.
     */
    ERROR_CODES["EMAIL_CONFIRM_TOKEN_EXPIRED"] = "EMAIL_CONFIRM_TOKEN_EXPIRED";
    /**
     * INVITE_TOKEN_EXPIRED is returned when a given invite token has expired.
     */
    ERROR_CODES["INVITE_TOKEN_EXPIRED"] = "INVITE_TOKEN_EXPIRED";
    /**
     * RATE_LIMIT_EXCEEDED is returned when an operation is performed too many
     * times by the same user.
     */
    ERROR_CODES["RATE_LIMIT_EXCEEDED"] = "RATE_LIMIT_EXCEEDED";
    /**
     * SCRAPE_FAILED is returned when a scrape operation has failed.
     */
    ERROR_CODES["SCRAPE_FAILED"] = "SCRAPE_FAILED";
    /**
     * JWT_REVOKED is returned when the token referenced has been revoked.
     */
    ERROR_CODES["JWT_REVOKED"] = "JWT_REVOKED";
    /*
     * INVITE_REQUIRES_EMAIL_ADDRESSES is returned when an invite is requested
     * without any email addresses specified.
     */
    ERROR_CODES["INVITE_REQUIRES_EMAIL_ADDRESSES"] = "INVITE_REQUIRES_EMAIL_ADDRESSES";
    /**
     * LIVE_UPDATES_DISABLED is returned when a websocket request is attempted by
     * someone now allowed when it is disabled on the tenant level.
     */
    ERROR_CODES["LIVE_UPDATES_DISABLED"] = "LIVE_UPDATES_DISABLED";
    /**
     * PERSISTED_QUERY_NOT_FOUND is returned when a query is executed specifying a
     * persisted query that can not be found.
     */
    ERROR_CODES["PERSISTED_QUERY_NOT_FOUND"] = "PERSISTED_QUERY_NOT_FOUND";
    /**
     * RAW_QUERY_NOT_AUTHORIZED is returned when a query is executed that is not a
     * persisted query when the server has configured such queries are required by
     * all non-admin users.
     */
    ERROR_CODES["RAW_QUERY_NOT_AUTHORIZED"] = "RAW_QUERY_NOT_AUTHORIZED";
    ERROR_CODES["USER_ALREADY_PREMOD"] = "USER_ALREADY_PREMOD";
    /**
     * INVITE_INCLUDES_EXISTING_USER is returned when attempting to invite a user who already exists
     */
    ERROR_CODES["INVITE_INCLUDES_EXISTING_USER"] = "INVITE_INCLUDES_EXISTING_USER";
    /**
     * REPEAT_POST is returned if a user attempts to post the same comment more than once
     * in a row within a given time frame
     */
    ERROR_CODES["REPEAT_POST"] = "REPEAT_POST";
    /**
     * INSTALLATION_FORBIDDEN is returned when an installation is attempted
     * when it is not authorized to do so.
     */
    ERROR_CODES["INSTALLATION_FORBIDDEN"] = "INSTALLATION_FORBIDDEN";
    ERROR_CODES["DUPLICATE_SITE_ORIGIN"] = "DUPLICATE_SITE_ORIGIN";
    ERROR_CODES["VALIDATION"] = "VALIDATION";
    ERROR_CODES["USER_BIO_TOO_LONG"] = "USER_BIO_TOO_LONG";
    ERROR_CODES["COMMENT_EDIT_WINDOW_EXPIRED"] = "COMMENT_EDIT_WINDOW_EXPIRED";
    /**
     * AUTHOR_ALREADY_HAS_RATED_STORY is returned when the author has already
     * rated a story and attempts to do so again.
     */
    ERROR_CODES["AUTHOR_ALREADY_HAS_RATED_STORY"] = "AUTHOR_ALREADY_HAS_RATED_STORY";
    /**
     * CANNOT_CREATE_COMMENT_ON_ARCHIVED_STORY is thrown when a user attempts to
     * publish a comment on an archived story. We do not allow creating comments
     * or replies on archived comment data.
     */
    ERROR_CODES["CANNOT_CREATE_COMMENT_ON_ARCHIVED_STORY"] = "CANNOT_CREATE_COMMENT_ON_ARCHIVED_STORY";
    /**
     * CANNOT_OPEN_AN_ARCHIVED_STORY is thrown when a user attempts to open a
     * story that is archived or actively being archived.
     */
    ERROR_CODES["CANNOT_OPEN_AN_ARCHIVED_STORY"] = "CANNOT_OPEN_AN_ARCHIVED_STORY";
    /**
     * CANNOT_MERGE_AN_ARCHIVED_STORY is thrown when a user attempts to merge a
     * story that is archived or actively being archived.
     */
    ERROR_CODES["CANNOT_MERGE_AN_ARCHIVED_STORY"] = "CANNOT_MERGE_AN_ARCHIVED_STORY";
    /**
     * USERNAME_ALREADY_EXISTS is thrown when a user is signing up or changing
     * their username and the provided username has already been taken.
     */
    ERROR_CODES["USERNAME_ALREADY_EXISTS"] = "USERNAME_ALREADY_EXISTS";
    /**
     * UNABLE_TO_UPDATE_STORY_URL is thrown when a story already exists for
     * a storyID and we were unable to update the url for that story ID.
     */
    ERROR_CODES["UNABLE_TO_UPDATE_STORY_URL"] = "UNABLE_TO_UPDATE_STORY_URL";
    /**
     * DATA_CACHING_NOT_AVAILABLE is thrown when someone tries to enact a data
     * caching action when it is not available for that tenant.
     */
    ERROR_CODES["DATA_CACHING_NOT_AVAILABLE"] = "DATA_CACHING_NOT_AVAILABLE";
    /**
     * INVALID_FLAIR_BADGE_NAME is thrown when someone tries to create a custom
     * flair badge with a name that includes invalid characters.
     */
    ERROR_CODES["INVALID_FLAIR_BADGE_NAME"] = "INVALID_FLAIR_BADGE_NAME";
})(ERROR_CODES = exports.ERROR_CODES || (exports.ERROR_CODES = {}));

import React, { FunctionComponent } from "react";

const ActiveNotificationBellIcon: FunctionComponent = () => {
  return (
    <svg viewBox="-1.25 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.2291 4.85596C13.2291 5.519 13.4925 6.15488 13.9614 6.62372C14.4302 7.09257 15.0661 7.35596 15.7291 7.35596C16.3922 7.35596 17.0281 7.09257 17.4969 6.62372C17.9657 6.15488 18.2291 5.519 18.2291 4.85596C18.2291 4.19292 17.9657 3.55703 17.4969 3.08819C17.0281 2.61935 16.3922 2.35596 15.7291 2.35596C15.0661 2.35596 14.4302 2.61935 13.9614 3.08819C13.4925 3.55703 13.2291 4.19292 13.2291 4.85596Z"
        fill="#EB5757"
      />
      <path
        d="M7.60413 18.125C7.70997 18.4856 7.92976 18.8022 8.23059 19.0274C8.53141 19.2526 8.89709 19.3743 9.27288 19.3743C9.64866 19.3743 10.0143 19.2526 10.3152 19.0274C10.616 18.8022 10.8358 18.4856 10.9416 18.125"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.27087 2.5V0.625"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.54 9.96736C15.6975 14.764 16.7709 15.6249 16.7709 15.6249H1.77087C1.77087 15.6249 3.02087 14.0282 3.02087 8.74986C3.02181 7.77073 3.25256 6.80548 3.69457 5.93178C4.13658 5.05809 4.7775 4.30035 5.56577 3.71953C6.35403 3.1387 7.26763 2.75102 8.23304 2.58767C9.19846 2.42432 10.1887 2.48987 11.1242 2.77903"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ActiveNotificationBellIcon;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GQLCOMMENT_FLAG_DETECTED_REASON = exports.GQLCACHE_CONTROL_SCOPE = exports.GQLUSER_AUTH_CONDITIONS = exports.GQLMODERATION_QUEUE = exports.GQLCOMMENT_FLAG_REPORTED_REASON = exports.GQLNOTIFICATION_TYPE = exports.GQLDSAReportHistoryType = exports.GQLDSAReportDecisionLegality = exports.GQLDSAReportStatus = exports.GQLDSAREPORT_STATUS_FILTER = exports.GQLREPORT_SORT = exports.GQLDSA_METHOD_OF_REDRESS = exports.GQLNEW_USER_MODERATION = exports.GQLFEATURE_FLAG = exports.GQLCOMMENT_BODY_FORMAT = exports.GQLWEBHOOK_EVENT_NAME = exports.GQLUSER_STATUS_FILTER = exports.GQLDIGEST_FREQUENCY = exports.GQLUSER_STATUS = exports.GQLREJECTION_REASON_CODE = exports.GQLSTORY_MODE = exports.GQLMODERATION_MODE = exports.GQLSTORY_STATUS = exports.GQLCOMMENT_SORT = exports.GQLUSER_ROLE = exports.GQLCOMMENT_FLAG_REASON = exports.GQLTAG = exports.GQLCOMMENT_STATUS = void 0;
var GQLCOMMENT_STATUS;
(function (GQLCOMMENT_STATUS) {
    /**
     * The comment is not PREMOD, but was not applied a moderation status by a
     * moderator.
     */
    GQLCOMMENT_STATUS["NONE"] = "NONE";
    /**
     * The comment has been approved by a moderator.
     */
    GQLCOMMENT_STATUS["APPROVED"] = "APPROVED";
    /**
     * The comment has been rejected by a moderator.
     */
    GQLCOMMENT_STATUS["REJECTED"] = "REJECTED";
    /**
     * The comment was created while the stories premoderation option was on, and
     * new comments that haven't been moderated yet are referred to as
     * "premoderated" or "premod" comments.
     */
    GQLCOMMENT_STATUS["PREMOD"] = "PREMOD";
    /**
     * SYSTEM_WITHHELD represents a comment that was withheld by the system because
     * it was flagged by an internal process for further review.
     */
    GQLCOMMENT_STATUS["SYSTEM_WITHHELD"] = "SYSTEM_WITHHELD";
})(GQLCOMMENT_STATUS = exports.GQLCOMMENT_STATUS || (exports.GQLCOMMENT_STATUS = {}));
var GQLTAG;
(function (GQLTAG) {
    /**
     * STAFF is used when a Comment is written by a User that has the STAFF, ADMIN,
     * or MODERATOR role.
     */
    GQLTAG["STAFF"] = "STAFF";
    /**
     * ADMIN is used when a Comment is written by a User that has the ADMIN,
     * role.
     */
    GQLTAG["ADMIN"] = "ADMIN";
    /**
     * MODERATOR is used when a Comment is written by a User that has the MODERATOR,
     * role.
     */
    GQLTAG["MODERATOR"] = "MODERATOR";
    /**
     * MEMBER is used when a Comment is written by a User that has the Member role.
     */
    GQLTAG["MEMBER"] = "MEMBER";
    /**
     * FEATURED is used when a Comment is marked as such by a staff member.
     */
    GQLTAG["FEATURED"] = "FEATURED";
    /**
     * EXPERT is used when an a Comment is written by a User that is assigned as
     * an expert on a story.
     */
    GQLTAG["EXPERT"] = "EXPERT";
    /**
     * UNANSWERED is used when a Comment is written by a user and has not been
     * answered by an expert on a Story that is set to Q&A.
     */
    GQLTAG["UNANSWERED"] = "UNANSWERED";
    /**
     * REVIEW is used when a Comment is written with a rating on a Story that is set
     * to Ratings and Reviews.
     */
    GQLTAG["REVIEW"] = "REVIEW";
    /**
     * QUESTION is used when a Comment is written without a rating on a Story
     * that is set to Ratings and Reviews.
     */
    GQLTAG["QUESTION"] = "QUESTION";
})(GQLTAG = exports.GQLTAG || (exports.GQLTAG = {}));
/**
 * COMMENT_FLAG_REASON is the union of the COMMENT_FLAG_REPORTED_REASON
 * and COMMENT_FLAG_DETECTED_REASON types.
 */
var GQLCOMMENT_FLAG_REASON;
(function (GQLCOMMENT_FLAG_REASON) {
    GQLCOMMENT_FLAG_REASON["COMMENT_REPORTED_OFFENSIVE"] = "COMMENT_REPORTED_OFFENSIVE";
    GQLCOMMENT_FLAG_REASON["COMMENT_REPORTED_ABUSIVE"] = "COMMENT_REPORTED_ABUSIVE";
    GQLCOMMENT_FLAG_REASON["COMMENT_REPORTED_SPAM"] = "COMMENT_REPORTED_SPAM";
    GQLCOMMENT_FLAG_REASON["COMMENT_REPORTED_OTHER"] = "COMMENT_REPORTED_OTHER";
    GQLCOMMENT_FLAG_REASON["COMMENT_REPORTED_BIO"] = "COMMENT_REPORTED_BIO";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_TOXIC"] = "COMMENT_DETECTED_TOXIC";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_SPAM"] = "COMMENT_DETECTED_SPAM";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_LINKS"] = "COMMENT_DETECTED_LINKS";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_BANNED_WORD"] = "COMMENT_DETECTED_BANNED_WORD";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_SUSPECT_WORD"] = "COMMENT_DETECTED_SUSPECT_WORD";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_RECENT_HISTORY"] = "COMMENT_DETECTED_RECENT_HISTORY";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_PREMOD_USER"] = "COMMENT_DETECTED_PREMOD_USER";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_NEW_COMMENTER"] = "COMMENT_DETECTED_NEW_COMMENTER";
    GQLCOMMENT_FLAG_REASON["COMMENT_DETECTED_REPEAT_POST"] = "COMMENT_DETECTED_REPEAT_POST";
})(GQLCOMMENT_FLAG_REASON = exports.GQLCOMMENT_FLAG_REASON || (exports.GQLCOMMENT_FLAG_REASON = {}));
var GQLUSER_ROLE;
(function (GQLUSER_ROLE) {
    GQLUSER_ROLE["COMMENTER"] = "COMMENTER";
    GQLUSER_ROLE["MEMBER"] = "MEMBER";
    GQLUSER_ROLE["STAFF"] = "STAFF";
    GQLUSER_ROLE["MODERATOR"] = "MODERATOR";
    GQLUSER_ROLE["ADMIN"] = "ADMIN";
})(GQLUSER_ROLE = exports.GQLUSER_ROLE || (exports.GQLUSER_ROLE = {}));
var GQLCOMMENT_SORT;
(function (GQLCOMMENT_SORT) {
    GQLCOMMENT_SORT["CREATED_AT_DESC"] = "CREATED_AT_DESC";
    GQLCOMMENT_SORT["CREATED_AT_ASC"] = "CREATED_AT_ASC";
    GQLCOMMENT_SORT["REPLIES_DESC"] = "REPLIES_DESC";
    GQLCOMMENT_SORT["REACTION_DESC"] = "REACTION_DESC";
})(GQLCOMMENT_SORT = exports.GQLCOMMENT_SORT || (exports.GQLCOMMENT_SORT = {}));
/**
 * STORY_STATUS represents filtering states that a Story can be in.
 */
var GQLSTORY_STATUS;
(function (GQLSTORY_STATUS) {
    /**
     * OPEN represents when a given Story is open for commenting.
     */
    GQLSTORY_STATUS["OPEN"] = "OPEN";
    /**
     * CLOSED represents when a given Story is not open for commenting.
     */
    GQLSTORY_STATUS["CLOSED"] = "CLOSED";
})(GQLSTORY_STATUS = exports.GQLSTORY_STATUS || (exports.GQLSTORY_STATUS = {}));
var GQLMODERATION_MODE;
(function (GQLMODERATION_MODE) {
    /**
     * Comments posted while in `PRE` mode will be labeled with a `PREMOD`
     * status and will require a moderator decision before being visible.
     */
    GQLMODERATION_MODE["PRE"] = "PRE";
    /**
     * Comments posted while in `POST` will be visible immediately.
     */
    GQLMODERATION_MODE["POST"] = "POST";
    /**
     * Comments posted while in `SPECIFIC_SITES_PRE` mode will be labeled with
     * a `PREMOD` status if the site is included in premoderateAllCommentsSites.
     */
    GQLMODERATION_MODE["SPECIFIC_SITES_PRE"] = "SPECIFIC_SITES_PRE";
})(GQLMODERATION_MODE = exports.GQLMODERATION_MODE || (exports.GQLMODERATION_MODE = {}));
var GQLSTORY_MODE;
(function (GQLSTORY_MODE) {
    /**
     * Coments is when a story is used for general commenting.
     */
    GQLSTORY_MODE["COMMENTS"] = "COMMENTS";
    /**
     * QA is used for when the story is in Q&A mode.
     */
    GQLSTORY_MODE["QA"] = "QA";
    /**
     * RATINGS_AND_REVIEWS is used for when the story should be used for ratings and
     * reviews.
     */
    GQLSTORY_MODE["RATINGS_AND_REVIEWS"] = "RATINGS_AND_REVIEWS";
})(GQLSTORY_MODE = exports.GQLSTORY_MODE || (exports.GQLSTORY_MODE = {}));
var GQLREJECTION_REASON_CODE;
(function (GQLREJECTION_REASON_CODE) {
    /**
     * OFFENSIVE represents a rejection of a comment for being offensive.
     */
    GQLREJECTION_REASON_CODE["OFFENSIVE"] = "OFFENSIVE";
    /**
     * ABUSIVE represents a rejection of a comment for being abusive.
     */
    GQLREJECTION_REASON_CODE["ABUSIVE"] = "ABUSIVE";
    /**
     * SPAM represents a rejection of a comment for being spam.
     */
    GQLREJECTION_REASON_CODE["SPAM"] = "SPAM";
    /**
     * BANNED_WORD represents a rejection of a comment for containing a banned word.
     */
    GQLREJECTION_REASON_CODE["BANNED_WORD"] = "BANNED_WORD";
    /**
     * AD represents a rejection of a comment for being and ad.
     */
    GQLREJECTION_REASON_CODE["AD"] = "AD";
    /**
     * ILLEGAL_CONTENT represents a rejection of a comment for containing illegal content.
     */
    GQLREJECTION_REASON_CODE["ILLEGAL_CONTENT"] = "ILLEGAL_CONTENT";
    /**
     * HARASSMENT_BULLYING represents a rejection of a comment for being harassment or bullying.
     */
    GQLREJECTION_REASON_CODE["HARASSMENT_BULLYING"] = "HARASSMENT_BULLYING";
    /**
     * MISINFORMATION represents a rejection of a comment for being misinformation.
     */
    GQLREJECTION_REASON_CODE["MISINFORMATION"] = "MISINFORMATION";
    /**
     * HATE_SPEECH represents a rejection of a comment for being hate speech.
     */
    GQLREJECTION_REASON_CODE["HATE_SPEECH"] = "HATE_SPEECH";
    /**
     * IRRELEVANT_CONTENT represents a rejection of a comment for being irrelevant to the conversation.
     */
    GQLREJECTION_REASON_CODE["IRRELEVANT_CONTENT"] = "IRRELEVANT_CONTENT";
    /**
     * OTHER is reserved for reasons that arent adequately described by the other options.
     */
    GQLREJECTION_REASON_CODE["OTHER"] = "OTHER";
})(GQLREJECTION_REASON_CODE = exports.GQLREJECTION_REASON_CODE || (exports.GQLREJECTION_REASON_CODE = {}));
/**
 * USER_STATUS is used to describe the current state of a User. A User may exist in
 * multiple states.
 */
var GQLUSER_STATUS;
(function (GQLUSER_STATUS) {
    /**
     * ACTIVE is used when a User is not suspended or banned.
     */
    GQLUSER_STATUS["ACTIVE"] = "ACTIVE";
    /**
     * BANNED is used when a User is banned.
     */
    GQLUSER_STATUS["BANNED"] = "BANNED";
    /**
     * SUSPENDED is used when a User is currently suspended.
     */
    GQLUSER_STATUS["SUSPENDED"] = "SUSPENDED";
    /**
     * PREMOD is used when a User is currently set to require pre-moderation.
     */
    GQLUSER_STATUS["PREMOD"] = "PREMOD";
    /**
     * WARNED is used when a user has been warned about behaviour and has not acknowledged
     */
    GQLUSER_STATUS["WARNED"] = "WARNED";
})(GQLUSER_STATUS = exports.GQLUSER_STATUS || (exports.GQLUSER_STATUS = {}));
var GQLDIGEST_FREQUENCY;
(function (GQLDIGEST_FREQUENCY) {
    /**
     * NONE will have the notifications send immediately rather than bundling for digesting.
     */
    GQLDIGEST_FREQUENCY["NONE"] = "NONE";
    /**
     * DAILY will queue up the notifications and send them daily.
     */
    GQLDIGEST_FREQUENCY["DAILY"] = "DAILY";
    /**
     * HOURLY will queue up the notifications and send them hourly.
     */
    GQLDIGEST_FREQUENCY["HOURLY"] = "HOURLY";
})(GQLDIGEST_FREQUENCY = exports.GQLDIGEST_FREQUENCY || (exports.GQLDIGEST_FREQUENCY = {}));
var GQLUSER_STATUS_FILTER;
(function (GQLUSER_STATUS_FILTER) {
    /**
     * ACTIVE is used when a User is not suspended or banned.
     */
    GQLUSER_STATUS_FILTER["ACTIVE"] = "ACTIVE";
    /**
     * BANNED is used when a User is banned.
     */
    GQLUSER_STATUS_FILTER["BANNED"] = "BANNED";
    /**
     * SUSPENDED is used when a User is currently suspended.
     */
    GQLUSER_STATUS_FILTER["SUSPENDED"] = "SUSPENDED";
    /**
     * PREMOD is used when a User is currently set to require pre-moderation.
     */
    GQLUSER_STATUS_FILTER["PREMOD"] = "PREMOD";
    /**
     * WARNED is used when a user has been warned about behaviour and has not acknowledged
     */
    GQLUSER_STATUS_FILTER["WARNED"] = "WARNED";
    /**
     * SITE_BANNED is used when a User is site banned.
     */
    GQLUSER_STATUS_FILTER["SITE_BANNED"] = "SITE_BANNED";
})(GQLUSER_STATUS_FILTER = exports.GQLUSER_STATUS_FILTER || (exports.GQLUSER_STATUS_FILTER = {}));
var GQLWEBHOOK_EVENT_NAME;
(function (GQLWEBHOOK_EVENT_NAME) {
    GQLWEBHOOK_EVENT_NAME["STORY_CREATED"] = "STORY_CREATED";
    GQLWEBHOOK_EVENT_NAME["COMMENT_CREATED"] = "COMMENT_CREATED";
    GQLWEBHOOK_EVENT_NAME["COMMENT_REPLY_CREATED"] = "COMMENT_REPLY_CREATED";
    GQLWEBHOOK_EVENT_NAME["COMMENT_LEFT_MODERATION_QUEUE"] = "COMMENT_LEFT_MODERATION_QUEUE";
})(GQLWEBHOOK_EVENT_NAME = exports.GQLWEBHOOK_EVENT_NAME || (exports.GQLWEBHOOK_EVENT_NAME = {}));
/**
 * COMMENT_BODY_FORMAT describes the various formats that a comment body can be
 * provided in.
 */
var GQLCOMMENT_BODY_FORMAT;
(function (GQLCOMMENT_BODY_FORMAT) {
    /**
     * HTML describes the format of the comment body using HTML.
     */
    GQLCOMMENT_BODY_FORMAT["HTML"] = "HTML";
    /**
     * PLAIN_TEXT describes the format of the comment body with the HTML stripped.
     */
    GQLCOMMENT_BODY_FORMAT["PLAIN_TEXT"] = "PLAIN_TEXT";
})(GQLCOMMENT_BODY_FORMAT = exports.GQLCOMMENT_BODY_FORMAT || (exports.GQLCOMMENT_BODY_FORMAT = {}));
var GQLFEATURE_FLAG;
(function (GQLFEATURE_FLAG) {
    /**
     * SECTIONS when enabled will allow filtering comments in the moderation queue
     * by section.
     */
    GQLFEATURE_FLAG["SECTIONS"] = "SECTIONS";
    /**
     * DISABLE_WARN_USER_OF_TOXIC_COMMENT when enabled will turn off warnings for
     * toxic comments.
     */
    GQLFEATURE_FLAG["DISABLE_WARN_USER_OF_TOXIC_COMMENT"] = "DISABLE_WARN_USER_OF_TOXIC_COMMENT";
    /**
     * ENABLE_QA when enabled will turn off Q&A features client side.
     */
    GQLFEATURE_FLAG["ENABLE_QA"] = "ENABLE_QA";
    /**
     * DEFAULT_QA_STORY_MODE will set the story mode to QA by default.
     */
    GQLFEATURE_FLAG["DEFAULT_QA_STORY_MODE"] = "DEFAULT_QA_STORY_MODE";
    /**
     * REDUCED_SECURITY_MODE when true will allow unpersisted queries to be executed
     * in production environments.
     */
    GQLFEATURE_FLAG["REDUCED_SECURITY_MODE"] = "REDUCED_SECURITY_MODE";
    /**
     * DISCUSSIONS will enable the discussions tab for the comment stream.
     */
    GQLFEATURE_FLAG["DISCUSSIONS"] = "DISCUSSIONS";
    /**
     * READ_MORE_NEW_TAB will enable opening a new tab when a user clicks on
     * "read more of this conversation" in the comment stream.
     */
    GQLFEATURE_FLAG["READ_MORE_NEW_TAB"] = "READ_MORE_NEW_TAB";
    /**
     * AVATARS will enable pulling an avatar URL from sso token and displaying it
     * next to user comments
     */
    GQLFEATURE_FLAG["AVATARS"] = "AVATARS";
    /**
     * NEW_COMMENT_COUNT enables the comment count update feature.
     */
    GQLFEATURE_FLAG["NEW_COMMENT_COUNT"] = "NEW_COMMENT_COUNT";
    /**
     * EXTERNAL_MEDIA enables embedding external images
     */
    GQLFEATURE_FLAG["EXTERNAL_MEDIA"] = "EXTERNAL_MEDIA";
    /**
     * RTE_SARCASM enables sarcasm RTE feature
     */
    GQLFEATURE_FLAG["RTE_SARCASM"] = "RTE_SARCASM";
    /**
     * VIEWER_COUNT when true will enable the display and tracking of the viewer
     * count.
     */
    GQLFEATURE_FLAG["VIEWER_COUNT"] = "VIEWER_COUNT";
    /**
     * ALTERNATE_OLDEST_FIRST_VIEW when enabled will move the create comment form to
     * the bottom of the stream when sorting the comment stream by oldest first.
     */
    GQLFEATURE_FLAG["ALTERNATE_OLDEST_FIRST_VIEW"] = "ALTERNATE_OLDEST_FIRST_VIEW";
    /**
     * ENABLE_RATINGS_AND_REVIEWS when enabled will allow for the ratings and reviews
     * functionality to be enabled.
     */
    GQLFEATURE_FLAG["ENABLE_RATINGS_AND_REVIEWS"] = "ENABLE_RATINGS_AND_REVIEWS";
    /**
     * DISABLE_LIVE_UPDATES will disable live updates at the tenant level and
     * overrides the configuration option available in the Admin UI for that tenant.
     */
    GQLFEATURE_FLAG["DISABLE_LIVE_UPDATES"] = "DISABLE_LIVE_UPDATES";
    /**
     * EMAIL_META will add the email template context as a header to the SMTP as the
     * `X-Coral-Template` header.
     */
    GQLFEATURE_FLAG["EMAIL_META"] = "EMAIL_META";
    /**
     * COMMENT_SEEN will mark comment that has been seen/unseen.
     */
    GQLFEATURE_FLAG["COMMENT_SEEN"] = "COMMENT_SEEN";
    /**
     * Z_KEY will allow Z key traversal and the mobile toolbar.
     */
    GQLFEATURE_FLAG["Z_KEY"] = "Z_KEY";
    /**
     * CONFIGURE_PUBLIC_PROFILE_URL will allow configuration in the admin of a link to
     * an external profile for a user.
     */
    GQLFEATURE_FLAG["CONFIGURE_PUBLIC_PROFILE_URL"] = "CONFIGURE_PUBLIC_PROFILE_URL";
    /**
     * DATA_CACHE will allow the use of the data cache to store and load comments,
     * users, and commentActions more quickly. It is disabled by default.
     */
    GQLFEATURE_FLAG["DATA_CACHE"] = "DATA_CACHE";
})(GQLFEATURE_FLAG = exports.GQLFEATURE_FLAG || (exports.GQLFEATURE_FLAG = {}));
var GQLNEW_USER_MODERATION;
(function (GQLNEW_USER_MODERATION) {
    /**
     * New users who sign up with an email domain configured to this moderation
     * mode will be automatically banned
     */
    GQLNEW_USER_MODERATION["BAN"] = "BAN";
    /**
     * New users who sign up with an email domain configured to this moderation
     * mode will be automatically always pre-moderated
     */
    GQLNEW_USER_MODERATION["PREMOD"] = "PREMOD";
})(GQLNEW_USER_MODERATION = exports.GQLNEW_USER_MODERATION || (exports.GQLNEW_USER_MODERATION = {}));
var GQLDSA_METHOD_OF_REDRESS;
(function (GQLDSA_METHOD_OF_REDRESS) {
    GQLDSA_METHOD_OF_REDRESS["NONE"] = "NONE";
    GQLDSA_METHOD_OF_REDRESS["EMAIL"] = "EMAIL";
    GQLDSA_METHOD_OF_REDRESS["URL"] = "URL";
})(GQLDSA_METHOD_OF_REDRESS = exports.GQLDSA_METHOD_OF_REDRESS || (exports.GQLDSA_METHOD_OF_REDRESS = {}));
var GQLREPORT_SORT;
(function (GQLREPORT_SORT) {
    GQLREPORT_SORT["CREATED_AT_DESC"] = "CREATED_AT_DESC";
    GQLREPORT_SORT["CREATED_AT_ASC"] = "CREATED_AT_ASC";
})(GQLREPORT_SORT = exports.GQLREPORT_SORT || (exports.GQLREPORT_SORT = {}));
var GQLDSAREPORT_STATUS_FILTER;
(function (GQLDSAREPORT_STATUS_FILTER) {
    GQLDSAREPORT_STATUS_FILTER["AWAITING_REVIEW"] = "AWAITING_REVIEW";
    GQLDSAREPORT_STATUS_FILTER["UNDER_REVIEW"] = "UNDER_REVIEW";
    GQLDSAREPORT_STATUS_FILTER["COMPLETED"] = "COMPLETED";
    GQLDSAREPORT_STATUS_FILTER["VOID"] = "VOID";
})(GQLDSAREPORT_STATUS_FILTER = exports.GQLDSAREPORT_STATUS_FILTER || (exports.GQLDSAREPORT_STATUS_FILTER = {}));
/**
 * DSAReportStatus keeps track of where a DSAReport is in the process of being reviewed
 */
var GQLDSAReportStatus;
(function (GQLDSAReportStatus) {
    GQLDSAReportStatus["AWAITING_REVIEW"] = "AWAITING_REVIEW";
    GQLDSAReportStatus["UNDER_REVIEW"] = "UNDER_REVIEW";
    GQLDSAReportStatus["COMPLETED"] = "COMPLETED";
    /**
     * VOID is set for a DSAReport if its reported comment/user are deleted while the report is in review
     */
    GQLDSAReportStatus["VOID"] = "VOID";
})(GQLDSAReportStatus = exports.GQLDSAReportStatus || (exports.GQLDSAReportStatus = {}));
/**
 * DSAReportDecision keeps track of whether a DSAReport was determined illegal or not
 */
var GQLDSAReportDecisionLegality;
(function (GQLDSAReportDecisionLegality) {
    GQLDSAReportDecisionLegality["LEGAL"] = "LEGAL";
    GQLDSAReportDecisionLegality["ILLEGAL"] = "ILLEGAL";
})(GQLDSAReportDecisionLegality = exports.GQLDSAReportDecisionLegality || (exports.GQLDSAReportDecisionLegality = {}));
var GQLDSAReportHistoryType;
(function (GQLDSAReportHistoryType) {
    GQLDSAReportHistoryType["STATUS_CHANGED"] = "STATUS_CHANGED";
    GQLDSAReportHistoryType["NOTE"] = "NOTE";
    GQLDSAReportHistoryType["DECISION_MADE"] = "DECISION_MADE";
    GQLDSAReportHistoryType["SHARE"] = "SHARE";
})(GQLDSAReportHistoryType = exports.GQLDSAReportHistoryType || (exports.GQLDSAReportHistoryType = {}));
var GQLNOTIFICATION_TYPE;
(function (GQLNOTIFICATION_TYPE) {
    GQLNOTIFICATION_TYPE["UNKNOWN"] = "UNKNOWN";
    GQLNOTIFICATION_TYPE["COMMENT_FEATURED"] = "COMMENT_FEATURED";
    GQLNOTIFICATION_TYPE["COMMENT_APPROVED"] = "COMMENT_APPROVED";
    GQLNOTIFICATION_TYPE["COMMENT_REJECTED"] = "COMMENT_REJECTED";
    GQLNOTIFICATION_TYPE["ILLEGAL_REJECTED"] = "ILLEGAL_REJECTED";
    GQLNOTIFICATION_TYPE["DSA_REPORT_DECISION_MADE"] = "DSA_REPORT_DECISION_MADE";
})(GQLNOTIFICATION_TYPE = exports.GQLNOTIFICATION_TYPE || (exports.GQLNOTIFICATION_TYPE = {}));
/**
 * COMMENT_FLAG_REPORTED_REASON is a reason that is reported by a User on a
 * Comment.
 */
var GQLCOMMENT_FLAG_REPORTED_REASON;
(function (GQLCOMMENT_FLAG_REPORTED_REASON) {
    /**
     * COMMENT_REPORTED_OFFENSIVE is used when a User reported a Comment as being
     * offensive.
     */
    GQLCOMMENT_FLAG_REPORTED_REASON["COMMENT_REPORTED_OFFENSIVE"] = "COMMENT_REPORTED_OFFENSIVE";
    /**
     * COMMENT_REPORTED_ABUSIVE is used when a User reported a Comment as being
     * abusive.
     */
    GQLCOMMENT_FLAG_REPORTED_REASON["COMMENT_REPORTED_ABUSIVE"] = "COMMENT_REPORTED_ABUSIVE";
    /**
     * COMMENT_REPORTED_SPAM is used when a User reported a Comment as appearing like
     * spam.
     */
    GQLCOMMENT_FLAG_REPORTED_REASON["COMMENT_REPORTED_SPAM"] = "COMMENT_REPORTED_SPAM";
    /**
     * COMMENT_REPORTED_OTHER is used when a User reported a Comment that doesn't
     * fit into the other reported reasons.
     */
    GQLCOMMENT_FLAG_REPORTED_REASON["COMMENT_REPORTED_OTHER"] = "COMMENT_REPORTED_OTHER";
    /**
     * COMMENT_REPORTED_BIO is used when a User reports a bio that is offensive or abusive.
     */
    GQLCOMMENT_FLAG_REPORTED_REASON["COMMENT_REPORTED_BIO"] = "COMMENT_REPORTED_BIO";
})(GQLCOMMENT_FLAG_REPORTED_REASON = exports.GQLCOMMENT_FLAG_REPORTED_REASON || (exports.GQLCOMMENT_FLAG_REPORTED_REASON = {}));
/**
 * MODERATION_QUEUE references the specific ModerationQueue that a given Comment
 * can be associated with.
 */
var GQLMODERATION_QUEUE;
(function (GQLMODERATION_QUEUE) {
    /**
     * UNMODERATED refers to the ModerationQueue for all Comments that have not been
     * moderated yet.
     */
    GQLMODERATION_QUEUE["UNMODERATED"] = "UNMODERATED";
    /**
     * REPORTED refers to the ModerationQueue for all Comments that have been
     * published, have not been moderated by a human yet, and have been reported by
     * a User via a flag.
     */
    GQLMODERATION_QUEUE["REPORTED"] = "REPORTED";
    /**
     * PENDING refers to the ModerationQueue for all Comments that were held back by
     * the system and require moderation in order to be published.
     */
    GQLMODERATION_QUEUE["PENDING"] = "PENDING";
})(GQLMODERATION_QUEUE = exports.GQLMODERATION_QUEUE || (exports.GQLMODERATION_QUEUE = {}));
/**
 * USER_AUTH_CONDITIONS describes conditions that would prevent a given User to
 * execute any set of mutations or reserved queries.
 */
var GQLUSER_AUTH_CONDITIONS;
(function (GQLUSER_AUTH_CONDITIONS) {
    /**
     * MISSING_NAME is provided when the User does not have an associated username or
     * display name.
     */
    GQLUSER_AUTH_CONDITIONS["MISSING_NAME"] = "MISSING_NAME";
    /**
     * MISSING_EMAIL is provided when the User does not have an associated email
     * address.
     */
    GQLUSER_AUTH_CONDITIONS["MISSING_EMAIL"] = "MISSING_EMAIL";
    /**
     * BANNED is provided when the User is currently banned.
     */
    GQLUSER_AUTH_CONDITIONS["BANNED"] = "BANNED";
    /**
     * SUSPENDED is provided when the User is currently under an active suspension.
     */
    GQLUSER_AUTH_CONDITIONS["SUSPENDED"] = "SUSPENDED";
    /**
     * PENDING_DELETION is provided when the User is scheduled for deletion and will
     * remain after being deleted.
     */
    GQLUSER_AUTH_CONDITIONS["PENDING_DELETION"] = "PENDING_DELETION";
    /**
     * WARNED
     */
    GQLUSER_AUTH_CONDITIONS["WARNED"] = "WARNED";
})(GQLUSER_AUTH_CONDITIONS = exports.GQLUSER_AUTH_CONDITIONS || (exports.GQLUSER_AUTH_CONDITIONS = {}));
var GQLCACHE_CONTROL_SCOPE;
(function (GQLCACHE_CONTROL_SCOPE) {
    GQLCACHE_CONTROL_SCOPE["PUBLIC"] = "PUBLIC";
    GQLCACHE_CONTROL_SCOPE["PRIVATE"] = "PRIVATE";
})(GQLCACHE_CONTROL_SCOPE = exports.GQLCACHE_CONTROL_SCOPE || (exports.GQLCACHE_CONTROL_SCOPE = {}));
/**
 * COMMENT_FLAG_DETECTED_REASON is a reason that is detected by the system on a
 * Comment.
 */
var GQLCOMMENT_FLAG_DETECTED_REASON;
(function (GQLCOMMENT_FLAG_DETECTED_REASON) {
    /**
     * COMMENT_DETECTED_TOXIC is used when the Comment was detected as being toxic by
     * the system.
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_TOXIC"] = "COMMENT_DETECTED_TOXIC";
    /**
     * COMMENT_DETECTED_SPAM is used when the Comment was detected as having spam by
     * the system.
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_SPAM"] = "COMMENT_DETECTED_SPAM";
    /**
     * COMMENT_DETECTED_LINKS is used when the Comment was detected as containing
     * links.
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_LINKS"] = "COMMENT_DETECTED_LINKS";
    /**
     * COMMENT_DETECTED_BANNED_WORD is used when the Comment was detected as
     * containing a banned word.
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_BANNED_WORD"] = "COMMENT_DETECTED_BANNED_WORD";
    /**
     * COMMENT_DETECTED_SUSPECT_WORD is used when the Comment was detected as
     * containing a suspect word.
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_SUSPECT_WORD"] = "COMMENT_DETECTED_SUSPECT_WORD";
    /**
     * COMMENT_DETECTED_RECENT_HISTORY is used when a Comment author has exhibited a
     * recent history of rejected comments.
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_RECENT_HISTORY"] = "COMMENT_DETECTED_RECENT_HISTORY";
    /**
     * COMMENT_DETECTED_PREMOD_USER is used when a Comment author has been tagged as requiring premoderation
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_PREMOD_USER"] = "COMMENT_DETECTED_PREMOD_USER";
    /**
     * COMMENT_DETECTED_REPEAT_POST is used when a Comment's text exactly matches a previous recent comment from the same author
     */
    GQLCOMMENT_FLAG_DETECTED_REASON["COMMENT_DETECTED_REPEAT_POST"] = "COMMENT_DETECTED_REPEAT_POST";
})(GQLCOMMENT_FLAG_DETECTED_REASON = exports.GQLCOMMENT_FLAG_DETECTED_REASON || (exports.GQLCOMMENT_FLAG_DETECTED_REASON = {}));

/* tslint:disable */
/* eslint-disable */
/* @relayHash 65fd9120056a72810d432415acd806e2 */

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DSAReportDecisionLegality = "ILLEGAL" | "LEGAL" | "%future added value";
export type DSAReportHistoryType = "DECISION_MADE" | "NOTE" | "SHARE" | "STATUS_CHANGED" | "%future added value";
export type DSAReportStatus = "AWAITING_REVIEW" | "COMPLETED" | "UNDER_REVIEW" | "VOID" | "%future added value";
export type SingleReportRouteQueryVariables = {
    reportID: string;
};
export type SingleReportRouteQueryResponse = {
    readonly viewer: {
        readonly id: string;
    } | null;
    readonly dsaReport: {
        readonly id: string;
        readonly referenceID: string;
        readonly lawBrokenDescription: string;
        readonly additionalInformation: string;
        readonly createdAt: string;
        readonly status: DSAReportStatus | null;
        readonly reporter: {
            readonly id: string;
            readonly username: string | null;
        } | null;
        readonly history: ReadonlyArray<{
            readonly id: string;
            readonly createdAt: string;
            readonly type: DSAReportHistoryType | null;
            readonly decision: {
                readonly legality: DSAReportDecisionLegality;
                readonly legalGrounds: string | null;
                readonly detailedExplanation: string | null;
            } | null;
        } | null> | null;
        readonly decision: {
            readonly legality: DSAReportDecisionLegality;
            readonly legalGrounds: string | null;
            readonly detailedExplanation: string | null;
        } | null;
        readonly comment: {
            readonly " $fragmentRefs": FragmentRefs<"CommentAuthorContainer_comment" | "MediaContainer_comment">;
        } | null;
        readonly " $fragmentRefs": FragmentRefs<"ReportHistory_dsaReport" | "ReportShareButton_dsaReport" | "ReportMakeDecisionModal_dsaReport" | "ReportedComment_dsaReport" | "RelatedReports_dsaReport">;
    } | null;
};
export type SingleReportRouteQuery = {
    readonly response: SingleReportRouteQueryResponse;
    readonly variables: SingleReportRouteQueryVariables;
};



/*
query SingleReportRouteQuery(
  $reportID: ID!
) {
  viewer {
    id
  }
  dsaReport(id: $reportID) {
    id
    referenceID
    lawBrokenDescription
    additionalInformation
    createdAt
    status
    reporter {
      id
      username
    }
    history {
      id
      createdAt
      type
      decision {
        legality
        legalGrounds
        detailedExplanation
      }
    }
    decision {
      legality
      legalGrounds
      detailedExplanation
    }
    comment {
      ...CommentAuthorContainer_comment
      ...MediaContainer_comment
      id
    }
    ...ReportHistory_dsaReport
    ...ReportShareButton_dsaReport
    ...ReportMakeDecisionModal_dsaReport
    ...ReportedComment_dsaReport
    ...RelatedReports_dsaReport
  }
}

fragment CommentAuthorContainer_comment on Comment {
  author {
    id
    username
    status {
      suspension {
        active
      }
      premod {
        active
      }
      ban {
        active
      }
    }
  }
}

fragment MediaContainer_comment on Comment {
  id
  site {
    id
  }
  revision {
    media {
      __typename
      ... on GiphyMedia {
        url
        title
        width
        height
        still
        video
      }
      ... on TwitterMedia {
        url
      }
      ... on YouTubeMedia {
        url
        still
        title
      }
      ... on ExternalMedia {
        url
      }
    }
    id
  }
}

fragment RelatedReports_dsaReport on DSAReport {
  id
  relatedReports(first: 10, orderBy: CREATED_AT_DESC) {
    edges {
      node {
        id
        referenceID
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ReportHistory_dsaReport on DSAReport {
  id
  status
  createdAt
  history {
    id
    createdBy {
      username
      id
    }
    createdAt
    body
    type
    status
    decision {
      legality
      legalGrounds
      detailedExplanation
    }
  }
}

fragment ReportMakeDecisionModal_dsaReport on DSAReport {
  id
  comment {
    id
    revision {
      id
    }
  }
}

fragment ReportShareButton_dsaReport on DSAReport {
  id
  referenceID
  status
}

fragment ReportedComment_dsaReport on DSAReport {
  id
  comment {
    id
    deleted
    parent {
      author {
        id
        username
      }
      id
    }
    author {
      id
      username
    }
    createdAt
    editing {
      edited
    }
    rating
    body
    story {
      url
      metadata {
        title
      }
      id
    }
    status
    ...CommentAuthorContainer_comment
    ...MediaContainer_comment
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        ({
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "reportID"
        } as any)
    ], v1 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any), v2 = [
        (v1 /*: any*/)
    ], v3 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": (v2 /*: any*/),
        "storageKey": null
    } as any), v4 = [
        ({
            "kind": "Variable",
            "name": "id",
            "variableName": "reportID"
        } as any)
    ], v5 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "referenceID",
        "storageKey": null
    } as any), v6 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "lawBrokenDescription",
        "storageKey": null
    } as any), v7 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "additionalInformation",
        "storageKey": null
    } as any), v8 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "createdAt",
        "storageKey": null
    } as any), v9 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
    } as any), v10 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "username",
        "storageKey": null
    } as any), v11 = [
        (v1 /*: any*/),
        (v10 /*: any*/)
    ], v12 = ({
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "reporter",
        "plural": false,
        "selections": (v11 /*: any*/),
        "storageKey": null
    } as any), v13 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "type",
        "storageKey": null
    } as any), v14 = ({
        "alias": null,
        "args": null,
        "concreteType": "DSAReportDecision",
        "kind": "LinkedField",
        "name": "decision",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legality",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "legalGrounds",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "detailedExplanation",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any), v15 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "body",
        "storageKey": null
    } as any), v16 = [
        ({
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "active",
            "storageKey": null
        } as any)
    ], v17 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any), v18 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "url",
        "storageKey": null
    } as any), v19 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any), v20 = ({
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "still",
        "storageKey": null
    } as any), v21 = [
        (v18 /*: any*/)
    ], v22 = [
        ({
            "kind": "Literal",
            "name": "first",
            "value": 10
        } as any),
        ({
            "kind": "Literal",
            "name": "orderBy",
            "value": "CREATED_AT_DESC"
        } as any)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SingleReportRouteQuery",
            "selections": [
                (v3 /*: any*/),
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "DSAReport",
                    "kind": "LinkedField",
                    "name": "dsaReport",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v12 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReportHistoryItem",
                            "kind": "LinkedField",
                            "name": "history",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                (v8 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v14 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "CommentAuthorContainer_comment"
                                },
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "MediaContainer_comment"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ReportHistory_dsaReport"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ReportShareButton_dsaReport"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ReportMakeDecisionModal_dsaReport"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "ReportedComment_dsaReport"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "RelatedReports_dsaReport"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "Query",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SingleReportRouteQuery",
            "selections": [
                (v3 /*: any*/),
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "DSAReport",
                    "kind": "LinkedField",
                    "name": "dsaReport",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v5 /*: any*/),
                        (v6 /*: any*/),
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        (v9 /*: any*/),
                        (v12 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DSAReportHistoryItem",
                            "kind": "LinkedField",
                            "name": "history",
                            "plural": true,
                            "selections": [
                                (v1 /*: any*/),
                                (v8 /*: any*/),
                                (v13 /*: any*/),
                                (v14 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "createdBy",
                                    "plural": false,
                                    "selections": [
                                        (v10 /*: any*/),
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v15 /*: any*/),
                                (v9 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v14 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "author",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        (v10 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "UserStatus",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "SuspensionStatus",
                                                    "kind": "LinkedField",
                                                    "name": "suspension",
                                                    "plural": false,
                                                    "selections": (v16 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "PremodStatus",
                                                    "kind": "LinkedField",
                                                    "name": "premod",
                                                    "plural": false,
                                                    "selections": (v16 /*: any*/),
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "BanStatus",
                                                    "kind": "LinkedField",
                                                    "name": "ban",
                                                    "plural": false,
                                                    "selections": (v16 /*: any*/),
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                (v1 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Site",
                                    "kind": "LinkedField",
                                    "name": "site",
                                    "plural": false,
                                    "selections": (v2 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "CommentRevision",
                                    "kind": "LinkedField",
                                    "name": "revision",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": null,
                                            "kind": "LinkedField",
                                            "name": "media",
                                            "plural": false,
                                            "selections": [
                                                (v17 /*: any*/),
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v18 /*: any*/),
                                                        (v19 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "width",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "height",
                                                            "storageKey": null
                                                        },
                                                        (v20 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "video",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "GiphyMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v21 /*: any*/),
                                                    "type": "TwitterMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        (v18 /*: any*/),
                                                        (v20 /*: any*/),
                                                        (v19 /*: any*/)
                                                    ],
                                                    "type": "YouTubeMedia",
                                                    "abstractKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": (v21 /*: any*/),
                                                    "type": "ExternalMedia",
                                                    "abstractKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "deleted",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Comment",
                                    "kind": "LinkedField",
                                    "name": "parent",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "author",
                                            "plural": false,
                                            "selections": (v11 /*: any*/),
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v8 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "EditInfo",
                                    "kind": "LinkedField",
                                    "name": "editing",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "edited",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rating",
                                    "storageKey": null
                                },
                                (v15 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Story",
                                    "kind": "LinkedField",
                                    "name": "story",
                                    "plural": false,
                                    "selections": [
                                        (v18 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "StoryMetadata",
                                            "kind": "LinkedField",
                                            "name": "metadata",
                                            "plural": false,
                                            "selections": [
                                                (v19 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v1 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v9 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": (v22 /*: any*/),
                            "concreteType": "DSAReportsConnection",
                            "kind": "LinkedField",
                            "name": "relatedReports",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DSAReportEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DSAReport",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                (v5 /*: any*/),
                                                (v17 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "cursor",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "PageInfo",
                                    "kind": "LinkedField",
                                    "name": "pageInfo",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "endCursor",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "hasNextPage",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": "relatedReports(first:10,orderBy:\"CREATED_AT_DESC\")"
                        },
                        {
                            "alias": null,
                            "args": (v22 /*: any*/),
                            "filters": [
                                "orderBy"
                            ],
                            "handle": "connection",
                            "key": "RelatedReports_relatedReports",
                            "kind": "LinkedHandle",
                            "name": "relatedReports"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "id": "65fd9120056a72810d432415acd806e2",
            "metadata": {},
            "name": "SingleReportRouteQuery",
            "operationKind": "query",
            "text": null
        }
    } as any;
})();
(node as any).hash = '589ed732af1cd468824933fdf63bab33';
export default node;

/* tslint:disable */
/* eslint-disable */

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type ModerationPhasesConfigContainer_local = {
    readonly dsaFeaturesEnabled: boolean | null;
    readonly " $refType": "ModerationPhasesConfigContainer_local";
};
export type ModerationPhasesConfigContainer_local$data = ModerationPhasesConfigContainer_local;
export type ModerationPhasesConfigContainer_local$key = {
    readonly " $data"?: ModerationPhasesConfigContainer_local$data;
    readonly " $fragmentRefs": FragmentRefs<"ModerationPhasesConfigContainer_local">;
};



const node: ReaderFragment = ({
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ModerationPhasesConfigContainer_local",
    "selections": [
        {
            "kind": "ClientExtension",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dsaFeaturesEnabled",
                    "storageKey": null
                }
            ]
        }
    ],
    "type": "Local",
    "abstractKey": null
} as any);
(node as any).hash = 'ffecf66d7f7fa9d33e7fd6e65604871c';
export default node;
